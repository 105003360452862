module directives {
    export module applicationcore {
        interface IStampInfoScope extends ng.IScope {
            createstampfullname: string,
            createstampdate: Date,
            updatestampfullname?: string,
            updatestampdate?: Date,
            openInfo(): void
        }

        interface IStampInfoModalScope extends ng.IScope {
            createstampfullname: string,
            createstampdate: Date,
            updatestampfullname?: string,
            updatestampdate?: Date,
        }
        
        export class stampInfoDirective implements ng.IDirective {
            restrict = 'E';
            template = `
<button type="button" class="btn btn-info" ng-click="openInfo()">
    <i class="fa fa-info-circle"></i>
</button>
        `;
            scope = {
                createstampfullname: "=",
                createstampdate: "=",
                updatestampfullname: "=",
                updatestampdate: "="
            }

            constructor(private $uibModal: ng.ui.bootstrap.IModalService) {
            }

            link = ($scope: IStampInfoScope, $element: ng.IAugmentedJQuery) => {
                $scope.openInfo = () => {
                    var modalInstance = this.$uibModal.open({
                        animation: true,
                        template: `
                                    <div class="modal-header">
                                        <h3 class="modal-title">Stamp Info</h3>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container-fluid">
                                            <div class="col-md-4" ng-if="createstampfullname !== undefined"><label><b>Created By: </b></label></div>
                                            <div class="col-md-8" ng-if="createstampfullname !== undefined">{{createstampfullname}} on {{createstampdate | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>
                                       </div>
                                       <div class="container-fluid">
                                           <div class="col-md-4" ng-if="updatestampfullname !== undefined"><label><b>Last Updated By: </b></label></div>
                                           <div class="col-md-8" ng-if="updatestampfullname !== undefined">{{updatestampfullname}} on {{updatestampdate | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>
                                       </div>
                                    </div>`,
                        resolve: {
                            items: function () {
                                return {
                                    createstampfullname: $scope.createstampfullname,
                                    createstampdate: $scope.createstampdate,
                                    updatestampfullname: $scope.updatestampfullname,
                                    updatestampdate: $scope.updatestampdate
                                }
                            }
                        },
                        controller: function ($scope: IStampInfoModalScope, items: IStampInfoModalScope) {
                            $scope.createstampfullname = items.createstampfullname;
                            $scope.createstampdate = items.createstampdate;
                            $scope.updatestampfullname = items.updatestampfullname;
                            $scope.updatestampdate = items.updatestampdate;
                        }
                    });

                    modalInstance.result.then(function (selectedItem) {

                    }, function () {

                    });
                };
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($uibModal) => new stampInfoDirective($uibModal);
                directive.$inject = ['$uibModal'];

                return directive;
            }
        }
        angular.module("app").directive("gtsStampInfo", stampInfoDirective.factory());
    }
}